import { Payload as DefaultLegentPayload } from 'recharts/types/component/DefaultLegendContent'
import { Payload as DefaultTooltipPayload } from 'recharts/types/component/DefaultTooltipContent'
import { NyuSeriesProperties } from '../types'
import { forecastPostfix } from '../utils/helpers'
import useGetLineData from './useGetLineData'

export type TooltipPayloads = DefaultTooltipPayload<number, string>[]

export type LegendPayloads = DefaultLegentPayload[]

export type Payloads = TooltipPayloads | LegendPayloads

export const isForecastDataKey = (dataKey: string) =>
    dataKey.includes(forecastPostfix)

function preferNonForecastDataKeys(dataKeys: string[]) {
    const nonForecastKeys = dataKeys.filter((key) => !isForecastDataKey(key))
    const keysToRemove = nonForecastKeys.map(
        (nonForecastKey) => nonForecastKey + forecastPostfix
    )
    return dataKeys.filter((dataKey) => !keysToRemove.includes(dataKey))
}

export default function useChartPayload({
    payload,
    seriesProperties,
}: {
    payload: Payloads
    seriesProperties: NyuSeriesProperties
}) {
    const allDataKeys = [
        ...new Set(payload.flatMap(({ dataKey }) => String(dataKey))),
    ]

    const dataKeys = preferNonForecastDataKeys(allDataKeys)

    const getLineData = useGetLineData(seriesProperties)

    return {
        dataKeys,
        getLineData,
    }
}
