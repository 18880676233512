import { useEffect, useRef, useState } from 'react'
import useGlobalState from '../hooks/useGlobalState'
import useIsDevelopmentDomain from '../hooks/useIsDevelopmentDomain'
import { cn } from '../utils/cn'
import { appVersion } from '../utils/options'
import Debugger from './Debugger'
import { withGlobalContextProvider } from './GlobalContext'
import Logger from './Logger'
import Template from './Template'
import TogglePanel from './TogglePanel'

function App({ dataUrl, buildTime }: { dataUrl?: string; buildTime: string }) {
    const downloadView = useGlobalState((state) => state.downloadView)
    const setState = useGlobalState((state) => state.setState)
    const container = useRef<HTMLDivElement | null>(null)
    const templateNode = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        setState({
            dataUrl,
            buildTime,
            container: container.current,
            downloadView: false,
            templateNode: templateNode.current,
        })

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [dataUrl, buildTime, container])

    // Debug mode

    const isDev = useIsDevelopmentDomain() || !dataUrl
    const keySequence = useRef<string>('')
    const [debug, setDebug] = useState(isDev)

    const handleKeyDown = (e: KeyboardEvent) => {
        keySequence.current += e.key
        if (keySequence.current.includes('debug')) {
            setDebug(true)
            keySequence.current = ''
        }
    }

    return (
        <div ref={container} className="mx-auto max-w-screen-lg">
            <div className="relative">
                {downloadView && (
                    <div className="absolute z-50 flex size-full items-center justify-center bg-white">
                        Creating download...
                    </div>
                )}
                <div
                    ref={templateNode}
                    className={cn('bg-white', downloadView && 'p-1 md:p-4')}
                >
                    <Template />
                </div>
            </div>
            {debug && (
                <TogglePanel
                    title="Debug"
                    initialOpen={true}
                    info={`${appVersion} / ${buildTime}`}
                >
                    <Debugger />
                    <Logger />
                </TogglePanel>
            )}
        </div>
    )
}

export default withGlobalContextProvider(App)
