import { useEffect } from 'react'
import useFetchPipeline from '../../hooks/useFetchPipeline'
import useGlobalState from '../../hooks/useGlobalState'
import { NyuTemplateId } from '../../types'
import Page1 from './Page1'
import PageSelectize from './PageSelectize'

const pages = [
    Page1, // 1
    PageSelectize, // 2
    PageSelectize, // 3
    PageSelectize, // 4
    PageSelectize, // 5
    PageSelectize, // 6
    PageSelectize, // 7
    PageSelectize, // 8
]

function InvalidDataUrl() {
    return (
        <>
            <b>Data URL is missing or invalid</b>
            <p>
                This is expected behavior when the redbox-URL is called directly
            </p>
        </>
    )
}

function InvalidData() {
    return <b>Data seems to be invalid</b>
}

export function readTemplateIdFromUrl(dataUrl: string): NyuTemplateId {
    const url = new URL(dataUrl, window.location.href)
    const fileName = url.pathname.split('/').pop()
    const [id] = fileName?.split('_') || []

    if (!id) {
        throw new Error(
            `Template ID not found in URL: ${dataUrl}. The filename should be in the format e.g.: "1_gci.json"`
        )
    }

    const idNumber = parseInt(id)
    if (idNumber < 1 || idNumber > 8) {
        throw new Error(
            `Template ID out of range: ${idNumber}. The ID should be between 1 and 8`
        )
    }

    return idNumber as NyuTemplateId
}

export default function Template() {
    const dataUrl = useGlobalState((state) => state.dataUrl)
    const setState = useGlobalState((state) => state.setState)
    const setDataVersion = (dataVersion: string) => setState({ dataVersion })

    const result = useFetchPipeline(dataUrl)

    const dataVersion = result.value?.meta?.version
    useEffect(() => {
        setDataVersion(dataVersion)
    }, [dataVersion])

    if (!dataUrl) {
        return <InvalidDataUrl />
    }

    if (result.loading) {
        return (
            <div className="flex min-h-96 animate-pulse items-center justify-center">
                Loading...
            </div>
        )
    }

    if (result.error) {
        return <InvalidData />
    }

    const templateId = result.value?.meta?.templateId
    const Component = pages[templateId - 1] || InvalidData
    return <Component data={result.value} />
}
