import useChartPayload, { LegendPayloads } from '../../hooks/useChartPayload'
import { NyuSeriesProperties } from '../../types'
import DataKeyLabel from './DataKeyLabel'

export default function CustomLegend({
    payload = [],
    seriesProperties,
}: {
    payload?: LegendPayloads
    seriesProperties: NyuSeriesProperties
}) {
    const { dataKeys, getLineData } = useChartPayload({
        payload,
        seriesProperties,
    })

    return (
        <ul className="my-4 flex flex-row flex-wrap justify-start gap-3 sm:justify-center md:gap-6">
            {dataKeys.map((dataKey) => (
                <li key={dataKey}>
                    <DataKeyLabel lineData={getLineData(dataKey)!} />
                </li>
            ))}
        </ul>
    )
}
