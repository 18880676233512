import {
    NyuDataPoints,
    NyuDataProperties,
    NyuSeriesProperties,
    NyuUiProperties,
} from '../types'
import DownloadDataButton from './DownloadDataButton'
import { DropdownProps } from './Dropdown'
import Filter from './Filter'
import useGlobalState from '../hooks/useGlobalState'
import DHLLogo from '../assets/dhl_logo.jpg'
import NYULogo from '../assets/nyu_logo.jpg'
import { cn } from '../utils/cn'

const DownloadView = () => {
    return (
        <div className="my-4 flex w-full items-center md:my-0 md:w-auto">
            <img
                src={NYULogo}
                alt="NYU Logo"
                className="h-4 border-r-2 border-black pr-4"
                rel="preload"
            />
            <img
                src={DHLLogo}
                alt="DHL Logo"
                className="ml-4 h-5 md:w-auto"
                rel="preload"
            />
        </div>
    )
}

export default function Header({
    title,
    dataPoints,
    dataProperties,
    seriesProperties,
    activeDataPointKeys,
    startYearDropdownProps,
    endYearDropdownProps,
    prependRows = [],
    cite,
}: {
    title: string
    dataPoints: NyuDataPoints
    dataProperties: NyuDataProperties
    seriesProperties: NyuSeriesProperties
    uiProperties: NyuUiProperties
    activeDataPointKeys: string[]
    startYearDropdownProps: DropdownProps
    endYearDropdownProps: DropdownProps
    prependRows?: string[][]
    cite?: string
}) {
    const downloadView = useGlobalState((state) => state.downloadView)

    const hideAll = dataProperties.hideControls?.includes('all')
    const hideFilter =
        dataProperties.hideControls?.includes('filter') || hideAll
    const hideDownload =
        dataProperties.hideControls?.includes('download') || hideAll

    return (
        <>
            <div
                className={cn(
                    'items-center justify-end gap-2 hidden',
                    downloadView && 'flex'
                )}
            >
                <DownloadView />
            </div>
            <div
                className={cn(
                    'flex flex-row gap-2 flex-wrap items-end md:justify-end',
                    downloadView && 'hidden'
                )}
            >
                {!hideFilter && (
                    <Filter
                        {...{
                            startYearDropdownProps,
                            endYearDropdownProps,
                        }}
                    />
                )}
                {!hideDownload && (
                    <DownloadDataButton
                        title={title}
                        dataPoints={dataPoints}
                        dataProperties={dataProperties}
                        seriesProperties={seriesProperties}
                        activeDataPointKeys={activeDataPointKeys}
                        prependRows={prependRows}
                        cite={cite}
                    />
                )}
            </div>
        </>
    )
}
