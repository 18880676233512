import { useState } from 'react'

export default function useToggleState(
    initialState: Set<string>
): [Set<string>, (key: string) => void] {
    const [state, setState] = useState(new Set(initialState))
    const toggle = (key: string) => {
        const nextState = new Set(state)
        if (nextState.has(key)) {
            nextState.delete(key)
        } else {
            nextState.add(key)
        }
        setState(nextState)
    }
    return [state, toggle]
}
