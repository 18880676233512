import { useCallback, useState } from 'react'
import { NyuData, NyuDataSet, NyuGraphData, nyuGraphDataSchema } from '../types'

type TraverseObjectPathResult = {
    dataSetOrGraphData: NyuGraphData | NyuDataSet | null
    path: string[]
    pathSegmentsOptions: string[][]
}

type DataSetRecord = Record<string, NyuDataSet>

function findDefaultPathSegment(parentObject: DataSetRecord): string {
    const [firstKey] = Object.keys(parentObject).toSorted((a, b) => {
        const aIsDefault = parentObject[a]?.selectizeProperties?.isDefault
        const bIsDefault = parentObject[b]?.selectizeProperties?.isDefault
        if (aIsDefault === bIsDefault) {
            return 0
        } else if (aIsDefault) {
            return -1
        } else {
            return 1
        }
    })
    return firstKey
}

export const traverseDataSetPath = (
    parentObject: NyuDataSet | NyuGraphData, // Adjusted to accept both types
    path: string[],
    lastResult?: TraverseObjectPathResult
): TraverseObjectPathResult => {
    const resultIsGraphData = nyuGraphDataSchema.safeParse(
        lastResult?.dataSetOrGraphData
    ).success
    if (resultIsGraphData) {
        return lastResult!
    }

    // determine current path segment
    const [uncheckedSegment, ...remainingPath] = path
    const currentSegmentOptions = Object.keys(parentObject)
    const defaultPathSegment = findDefaultPathSegment(parentObject)
    const segment = currentSegmentOptions.includes(uncheckedSegment)
        ? uncheckedSegment
        : defaultPathSegment

    const childObject = (parentObject as DataSetRecord)[segment]

    const result = {
        path: [...(lastResult?.path || []), segment],
        pathSegmentsOptions: [
            ...(lastResult?.pathSegmentsOptions || []),
            currentSegmentOptions,
        ],
        dataSetOrGraphData: childObject,
    }

    return traverseDataSetPath(childObject, remainingPath, result)
}

export default function useTraverseDataSet(data: NyuData) {
    const [uncheckedPath, setPath] = useState<string[]>([])

    const { dataSetOrGraphData, path, pathSegmentsOptions } =
        traverseDataSetPath(data.dataset, uncheckedPath)

    const setPathSegment = useCallback(
        (index: number, value: string) => {
            const nextPath = [...path]
            nextPath[index] = value
            setPath(nextPath)
        },
        [path, setPath]
    )

    const graphData = Array.isArray(dataSetOrGraphData)
        ? null
        : (dataSetOrGraphData as NyuGraphData)

    return {
        graphData,
        path,
        pathSegmentsOptions,
        setPathSegment,
    }
}
