import { useCallback } from 'react'
import { NyuLineProperties, NyuSeriesProperties } from '../types'
import { forecastPostfix } from '../utils/helpers'

export default function useGetLineData(seriesProperties: NyuSeriesProperties) {
    return useCallback(
        (someDataKey: string) => {
            const s = someDataKey.replace(forecastPostfix, '')
            const lineProps: NyuLineProperties = seriesProperties.find(
                (item) => item.key === s
            )!
            return lineProps
        },
        [seriesProperties]
    )
}
