// @ts-ignore
import domtoimage from 'dom-to-image'

// @ts-ignore
import { saveAs } from 'file-saver'

import { useCallback, useMemo } from 'react'
import XLSX from 'xlsx'
import { isForecastDataKey } from '../hooks/useChartPayload'
import useGetLineData from '../hooks/useGetLineData'
import useGlobalState from '../hooks/useGlobalState'
import {
    NyuDataPoint,
    NyuDataPoints,
    NyuDataProperties,
    NyuSeriesProperties,
} from '../types'
import Dropdown from './Dropdown'
import { forecastPostfix, unwrapValueInArray } from '../utils/helpers'

type TableRows = (string | number)[][]

type Action = {
    label: string
    execute: () => void
}

function titleToFilename(title: string): string {
    return title.replace(':', ' -')
}

function csvEncode(tableRows: TableRows): string {
    return tableRows
        .map((row) =>
            row
                .map((cell) => {
                    const escapedCell = String(cell).replace(/"/g, '""')
                    return escapedCell
                })
                .join(',')
        )
        .join('\n')
}

const stringToByteArray = (s: string): ArrayBuffer => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i += 1) {
        view[i] = s.charCodeAt(i) & 0xff
    }
    return buf
}

const saveImageSnapshot = async (
    title: string,
    targetNode: HTMLElement | null
): Promise<void> => {
    if (!targetNode) {
        return
    }

    try {
        const dataUrl = await domtoimage.toPng(targetNode)
        saveAs(dataUrl, `${titleToFilename(title)}.png`)
    } catch (error) {
        console.error('Error while creating your snapshot: ', error)
    }
}

const saveTableAsCSV = (title: string, tableRows: TableRows): void => {
    const csvContent = csvEncode(tableRows)
    saveAs(
        new Blob([csvContent], {
            type: 'text/csv;charset=utf-8',
        }),
        `${titleToFilename(title)}.csv`,
        { autoBom: true }
    )
}

const saveTableAsExcel = (title: string, tableRows: TableRows): void => {
    const ws = XLSX.utils.aoa_to_sheet(tableRows)
    const wb = XLSX.utils.book_new()

    const sheetName = 'GCT Sheet'
    wb.Sheets[sheetName] = ws
    wb.SheetNames.push(sheetName)

    wb.Props = {
        Title: title,
        CreatedDate: new Date(),
    }

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

    saveAs(
        new Blob([stringToByteArray(wbout)], {
            type: 'application/octet-stream',
        }),
        `${titleToFilename(title)}.xlsx`
    )
}

// A temporary component until we have a proper dropdown component
function ActionDropdown({ actions }: { actions: Action[] }) {
    const setState = useGlobalState((state) => state.setState)
    const setDownloadView = (downloadView: boolean) =>
        setState({ downloadView })

    const actionLabels = useMemo(() => {
        return actions.map((item) => item.label)
    }, [actions])

    const handleAction = async (label: string) => {
        const action = actions.find((a) => a.label === label)
        if (!action) {
            return
        }

        try {
            await setDownloadView(true)
            await action.execute()
        } finally {
            await setDownloadView(false)
        }
    }

    return (
        <Dropdown
            value="Download"
            testId={'download-button'}
            options={actionLabels}
            setValue={handleAction}
            className="w-32 text-center"
        />
    )
}

const defaultTimeFormat = 'y'

const defaultCite = `Steven A. Altman and Caroline R. Bastian (${new Date().getFullYear()}). Global Connectedness Tracker. DHL Group.`

export default function DownloadDataButton({
    title,
    dataPoints,
    dataProperties,
    seriesProperties,
    activeDataPointKeys,
    prependRows = [],
    cite = defaultCite,
}: {
    title: string
    dataPoints: NyuDataPoints
    dataProperties: NyuDataProperties
    seriesProperties: NyuSeriesProperties
    activeDataPointKeys: string[]
    prependRows: string[][]
    cite?: string
}) {
    const templateNode = useGlobalState((state) => state.templateNode)

    const getLineData = useGetLineData(seriesProperties)

    const getTableRows = useCallback(() => {
        // keep the order of the active keys
        const sortedActiveDataPointKeys = seriesProperties
            .map(({ key }) => key)
            .filter((key) => activeDataPointKeys.includes(key))

        // add forecast keys
        const allKeys = new Set(
            dataPoints.flatMap((dataPoint) => Object.keys(dataPoint))
        )
        const activeDataPointKeysWithForecast =
            sortedActiveDataPointKeys.flatMap((key) => {
                if (allKeys.has(`${key}${forecastPostfix}`)) {
                    return [key, `${key}${forecastPostfix}`]
                }
                return [key]
            })

        const dataRows = dataPoints.map((dataPoint: NyuDataPoint) => [
            dataPoint.time,
            ...activeDataPointKeysWithForecast.map((key) => {
                if (
                    isForecastDataKey(key) &&
                    dataPoint[key.replace(forecastPostfix, '')] !== undefined
                ) {
                    // when there is both an actual value and a forecast value, we should not include the forecast value
                    return ''
                }

                return dataPoint[key!]
            }),
        ])

        const timeOrYear =
            (dataProperties.timeFormat || defaultTimeFormat) === 'y'
                ? 'Year'
                : 'Time'

        const headers = activeDataPointKeysWithForecast.map((key) => {
            const lineData = getLineData(key)
            if (isForecastDataKey(key)) {
                return `${lineData.label} (forecast)`
            }
            return lineData.label
        })

        const hasMultipleSources =
            Array.isArray(dataProperties.source) &&
            dataProperties.source.length > 1

        const note = dataProperties.note
            ? `Note: ${unwrapValueInArray(dataProperties.note)}`
            : ''
        const source = `Data source${hasMultipleSources ? 's' : ''}: ${unwrapValueInArray(dataProperties.source)}`

        return [
            ...prependRows,
            [timeOrYear, ...headers], // header
            ...dataRows,
            [], // padding
            [cite], // footer
            [source],
            [note],
        ]
    }, [
        dataPoints,
        activeDataPointKeys,
        getLineData,
        dataProperties,
        seriesProperties,
        prependRows,
    ])

    const actions: Action[] = useMemo(() => {
        return [
            {
                label: 'Image (PNG)',
                execute: () => saveImageSnapshot(title, templateNode),
            },
            {
                label: 'CSV',
                execute: () => saveTableAsCSV(title, getTableRows()),
            },
            {
                label: 'XLSX',
                execute: () => saveTableAsExcel(title, getTableRows()),
            },
        ]
    }, [getTableRows, title, templateNode])

    return <ActionDropdown actions={actions} />
}
