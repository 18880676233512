import { useCallback, useMemo } from 'react'
import useLog, { Log } from '../hooks/useLog'
import usePipelines, {
    AsyncPipelineStepFunction,
    Pipeline,
    Pipelines,
} from '../hooks/usePipelines'
import { nyuDataSchema, NyuTemplateId } from '../types'
import { readTemplateIdFromUrl } from '../comps/Template/Template'

const makeFetchUrl =
    (url: string): AsyncPipelineStepFunction =>
    (_lastResult: any, log: Log) => {
        log('fetching ' + url)
        return fetch(url)
    }

const parseJson: AsyncPipelineStepFunction = (response: Response) =>
    response.json()

const parseSchema: AsyncPipelineStepFunction = (data: any) =>
    Promise.resolve(nyuDataSchema.parse(data))

const fallbackUrls: {
    [key in NyuTemplateId]: string
} = {
    1: 'https://www.dhl.com/content/dam/dhl/global/microsites/dhl-nyu/fallback-data/1_gci.csv',
    2: 'https://www.dhl.com/content/dam/dhl/global/microsites/dhl-nyu/fallback-data/2_components.csv',
    3: 'https://www.dhl.com/content/dam/dhl/global/microsites/dhl-nyu/fallback-data/3_incomesector.csv',
    4: 'https://www.dhl.com/content/dam/dhl/global/microsites/dhl-nyu/fallback-data/4_shareshifts.csv',
    5: 'https://www.dhl.com/content/dam/dhl/global/microsites/dhl-nyu/fallback-data/5_blocs.csv',
    6: 'https://www.dhl.com/content/dam/dhl/global/microsites/dhl-nyu/fallback-data/6_geopolitical.csv',
    7: 'https://www.dhl.com/content/dam/dhl/global/microsites/dhl-nyu/fallback-data/7_regionalization.csv',
    8: 'https://www.dhl.com/content/dam/dhl/global/microsites/dhl-nyu/fallback-data/8_diversification.csv',
}

const fallbackEnabled = true

function urlIsBlob(url: string): boolean {
    return url.startsWith('blob:')
}

export default function useFetchPipeline(
    url: string
): ReturnType<typeof usePipelines> {
    const [log, clearLog] = useLog()

    const clearLogInPipeline: AsyncPipelineStepFunction = useCallback(
        (data) => {
            clearLog()
            return data
        },
        [clearLog]
    )

    const pipelines: Pipelines = useMemo<Pipelines>(() => {
        if (!url) {
            return []
        }
        const primaryPipeline: Pipeline = [
            [clearLogInPipeline, 'start'],
            [makeFetchUrl(url), 'load URL'],
            [parseJson, 'parse JSON'],
            [parseSchema, 'parse Schema'],
        ]

        if (!fallbackEnabled || urlIsBlob(url)) {
            return [primaryPipeline]
        }

        const fallbackTemplateId = readTemplateIdFromUrl(url)
        const fallbackUrl = fallbackUrls[fallbackTemplateId as NyuTemplateId]
        const fallbackPipeline: Pipeline = [
            [makeFetchUrl(fallbackUrl), 'fallback: load URL'],
            [parseJson, 'fallback: parse JSON'],
            [parseSchema, 'fallback: parse Schema'],
        ]

        return [primaryPipeline, fallbackPipeline]
    }, [url, clearLogInPipeline])

    return usePipelines(pipelines, log)
}
