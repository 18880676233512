import useChartPayload, {
    isForecastDataKey,
    TooltipPayloads,
} from '../../hooks/useChartPayload'
import {
    NyuDataPoint,
    NyuDataProperties,
    NyuLineProperties,
    NyuSeriesProperties,
} from '../../types'
import { cn } from '../../utils/cn'
import { formatDataPointValue } from '../../utils/helpers'
import DataKeyLabel from './DataKeyLabel'

function Row({
    label,
    asterisk = false,
    bold = false,
    children,
}: {
    label: string | React.ReactNode
    asterisk?: boolean
    bold?: boolean
    children: React.ReactNode
}) {
    return (
        <>
            <div className={cn(bold ? 'font-medium' : '', 'mr-3')}>{label}</div>
            <div className={cn(bold ? 'font-medium' : '', 'text-right')}>
                {children}{' '}
                <span className="inline-block w-2 text-gray-400">
                    {asterisk ? '*' : ' '}
                </span>
            </div>
        </>
    )
}

function TimeRow({
    time,
    timeFormat = 'y',
}: {
    time: string | number
    timeFormat?: string
}) {
    // TODO: support other time formats
    const label = timeFormat === 'y' ? 'Year' : 'Time'
    return (
        <Row label={label} bold>
            {time}
        </Row>
    )
}

function DataRow({
    dataKey,
    lineData,
    children,
}: {
    dataKey: string
    lineData: NyuLineProperties
    children: React.ReactNode
}) {
    return (
        <Row
            key={dataKey}
            label={<DataKeyLabel lineData={lineData} />}
            asterisk={isForecastDataKey(dataKey)}
        >
            {children}
        </Row>
    )
}

function TooltipEntry({
    dataKeys,
    payload,
    dataProperties,
    getLineData,
}: {
    dataKeys: string[]
    payload?: NyuDataPoint
    dataProperties: NyuDataProperties
    getLineData: (dataKey: string) => NyuLineProperties
}) {
    if (!payload) {
        return null
    }

    const sortedDataKeys = dataKeys.sort(
        (a, b) => Number(payload[b]) - Number(payload[a])
    )

    const hasForecastKeys = dataKeys.every(isForecastDataKey)

    const hasMultipleAxes = !!dataProperties.secondaryAxis
    const getDataFormat = (
        hasMultipleAxes: boolean,
        lineData: NyuLineProperties
    ) => {
        if (hasMultipleAxes && lineData.secondaryAxis) {
            return dataProperties.secondaryAxis!.format
        }

        return dataProperties.format
    }

    return (
        <div className="bg-white p-5 leading-relaxed shadow-lg">
            <div
                className="grid grid-cols-2"
                style={{ gridTemplateColumns: 'auto auto' }}
            >
                <TimeRow
                    time={payload.time}
                    timeFormat={dataProperties.timeFormat}
                />
                {sortedDataKeys.map((dataKey) => (
                    <DataRow
                        key={dataKey}
                        dataKey={dataKey}
                        lineData={getLineData(dataKey)!}
                    >
                        {formatDataPointValue(
                            Number(payload[dataKey]),
                            getDataFormat(hasMultipleAxes, getLineData(dataKey))
                        )}
                    </DataRow>
                ))}
            </div>
            {hasForecastKeys && (
                <div className="text-right text-gray-400">
                    * = forecasted values
                </div>
            )}
        </div>
    )
}

type CustomTooltipProps = {
    dataProperties: NyuDataProperties
    seriesProperties: NyuSeriesProperties
    payload?: TooltipPayloads
    active?: boolean
}

export default function CustomTooltip({
    dataProperties,
    seriesProperties,
    payload = [],
    active = false,
}: CustomTooltipProps) {
    const { dataKeys, getLineData } = useChartPayload({
        payload,
        seriesProperties,
    })

    if (!active || payload.length === 0) {
        return null
    }

    const [firstPayload] = payload
    return (
        <TooltipEntry
            {...firstPayload}
            dataKeys={dataKeys}
            dataProperties={dataProperties}
            getLineData={getLineData}
        />
    )
}
