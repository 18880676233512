import {
    NyuGroupProperties,
    NyuLineProperties,
    NyuSeriesProperties,
} from '../types'

export const getGroupsFromSeriesProperties = (
    seriesProperties: NyuSeriesProperties
): NyuGroupProperties[] => {
    const groups: NyuGroupProperties[] = seriesProperties.reduce(
        (acc: NyuGroupProperties[], cur: NyuLineProperties) => {
            const groupIndex = acc.findIndex(({ id }) => id === cur.group)
            if (groupIndex === -1) {
                acc.push({ id: cur.group, firstLine: cur, lines: [cur] })
            } else {
                acc[groupIndex].lines.push(cur)
            }
            return acc
        },
        []
    )
    return groups
}

export const getStartEndYearOptionsFromYears = (years: number[]) => {
    const maxMin = {
        start: Math.min(...years),
        end: Math.max(...years),
    }

    const startOptions = Array.from(
        { length: maxMin.end - maxMin.start },
        (_, i) => maxMin.start + i
    ).map(String)

    const endOptions = Array.from(
        { length: maxMin.end - maxMin.start },
        (_, i) => maxMin.start + i + 1
    ).map(String)

    return { startOptions, endOptions }
}

export function unwrap<T>(valueInArray: T[] | T): T {
    if (Array.isArray(valueInArray)) {
        return valueInArray[0]
    }
    return valueInArray
}

export function isDevelopmentDomain() {
    const { hostname, port } = window.location
    if (hostname === 'gct.rheindigital.org' && port !== '3000') {
        return true
    }
    if (hostname === 'localhost' && port === '1234') {
        return true
    }
    if (hostname === '127.0.0.1' && port === '1234') {
        return true
    }
    return false
}

export const unwrapValueInArray = (
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    wrappedValue?: string[] | {} | string
): string | null => {
    if (Array.isArray(wrappedValue) && wrappedValue.length > 0) {
        return wrappedValue.join(', ')
    }

    if (
        wrappedValue &&
        typeof wrappedValue === 'object' &&
        Object.keys(wrappedValue).length === 0
    ) {
        return null
    }

    return wrappedValue as string
}

function formatToThreeMeaningfulDigits(num: number): string {
    // Note: To avoid exponential notation, we need to convert the result of toPrecision() into an number and then back to a string
    const preciseNum = Number(num.toPrecision(3))
    return preciseNum.toString()
}

export const formatDataPointValue = (value: number, format: string) => {
    switch (format) {
        case '%':
            return formatToThreeMeaningfulDigits(value * 100) + '%'
        default:
            return `${formatToThreeMeaningfulDigits(value)}`
    }
}

export const limitStringAndAddEllipsis = (
    str: string,
    limit: number
): string => {
    if (str.length > limit) {
        return str.slice(0, limit) + '...'
    }
    return str
}

export const forecastPostfix = '_forecast'

export const selectizeLabelToPathSegment = (label: string = ''): string => {
    if (!label) {
        return ''
    }

    return `${label.replace('Select ', '')}: `
}

export const isCustomOption = (option: string) => {
    const customOptions = ['Custom', 'Custom...', 'Customize', 'Customize...']
    return customOptions.includes(option)
}
