import { useMemo } from 'react'
import { Line } from 'recharts'
import useFilterYears from '../../hooks/useFilterYears'
import useGlobalState from '../../hooks/useGlobalState'
import useTraverseDataSet from '../../hooks/useTraverseDataSet'
import { NyuData, NyuSeriesProperties } from '../../types'
import {
    getGroupsFromSeriesProperties,
    isCustomOption,
    selectizeLabelToPathSegment,
} from '../../utils/helpers'
import { lineTypes } from '../../utils/options'
import CustomLineChart from '../CustomLineChart'
import Dropdown from '../Dropdown'
import CustomActiveDot from '../Graph/CustomActiveDot'
import Header from '../Header'
import TabButtons from '../TabButtons'

export default function PageSelectize({ data }: { data: NyuData }) {
    const downloadView = useGlobalState((state) => state.downloadView)

    const {
        meta: { title },
        uiProperties,
    } = data

    const { graphData, path, pathSegmentsOptions, setPathSegment } =
        useTraverseDataSet(data)

    const { seriesProperties, dataProperties, selectizeProperties } = graphData!
    const groups = useMemo(() => {
        return getGroupsFromSeriesProperties(
            seriesProperties as NyuSeriesProperties
        )
    }, [seriesProperties])

    const groupIds = groups.map(({ id }) => id)
    const lines = groups.flatMap(({ lines }) => lines)

    const { dataPoints, startYearDropdownProps, endYearDropdownProps } =
        useFilterYears(graphData!.data)

    const hasMultipleAxes = !!dataProperties.secondaryAxis

    const pathWithoutCustomOption = path.filter((item) => !isCustomOption(item))
    const titleWithSelection = `${title} (${pathWithoutCustomOption.join(' - ')})`
    const prependRows = [[title], [pathWithoutCustomOption.join(', ')], []]

    const hideAll = dataProperties.hideControls?.includes('all')
    const hideSelector =
        dataProperties.hideControls?.includes('selector') || hideAll

    return (
        <>
            <div className="mb-2 flex flex-wrap items-start justify-between gap-2">
                <div className="text-xl font-bold" data-testid="headline">
                    {title}
                </div>
                {downloadView && (
                    <div className="text-right font-bold">
                        DHL Global Connectedness Tracker
                    </div>
                )}
            </div>
            {!downloadView && uiProperties?.firstSelectorAsTabs && (
                <div className="mb-2">
                    <TabButtons
                        value={path[0]}
                        options={pathSegmentsOptions[0]}
                        testId="dataset-selector-0-dropdown"
                        setValue={(value: string) => setPathSegment(0, value)}
                    />
                </div>
            )}
            <div className="mb-2 flex flex-col flex-wrap items-start justify-between gap-2 md:my-2 md:flex-row md:items-center md:justify-between">
                {downloadView && (
                    <div className="mt-1 inline-flex gap-1 whitespace-nowrap md:mt-0">
                        {path.map((segment: string, index: number) => {
                            if (isCustomOption(segment)) {
                                return null
                            }
                            return (
                                <div
                                    key={`${index}_${segment}_download`}
                                    className="group"
                                >
                                    <span className="capitalize text-gray-500">
                                        {selectizeLabelToPathSegment(
                                            selectizeProperties?.options[index]
                                        )}
                                    </span>
                                    <span className="text-gray-500">
                                        {segment}
                                    </span>
                                    <span className="text-gray-500 group-last:hidden">
                                        ,
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                )}
                {!downloadView && !hideSelector && (
                    <div className="flex w-full grow flex-wrap items-center gap-2 sm:flex-row sm:flex-nowrap md:w-auto">
                        {path.map((segment: string, index: number) => {
                            if (
                                index === 0 &&
                                uiProperties?.firstSelectorAsTabs
                            ) {
                                return null
                            }

                            return (
                                <div
                                    key={`${index}_${segment}`}
                                    className="flex flex-1"
                                >
                                    <Dropdown
                                        testId={`dataset-selector-${index}-dropdown`}
                                        className="text-left"
                                        isCombobox
                                        label={
                                            selectizeProperties?.options[index]
                                        }
                                        value={segment}
                                        options={pathSegmentsOptions[index]}
                                        setValue={(value: string) =>
                                            setPathSegment(index, value)
                                        }
                                    />
                                </div>
                            )
                        })}
                    </div>
                )}
                <div className="w-full items-end md:w-auto">
                    <Header
                        title={titleWithSelection}
                        dataPoints={dataPoints}
                        dataProperties={dataProperties}
                        seriesProperties={seriesProperties}
                        activeDataPointKeys={groupIds}
                        startYearDropdownProps={startYearDropdownProps}
                        endYearDropdownProps={endYearDropdownProps}
                        uiProperties={uiProperties}
                        prependRows={prependRows}
                        cite={data.meta.cite}
                    />
                </div>
            </div>
            <CustomLineChart
                dataPoints={dataPoints}
                dataProperties={dataProperties}
                seriesProperties={seriesProperties}
                uiProperties={uiProperties}
            >
                {lines.map((line) => {
                    if (line.secondaryAxis && !hasMultipleAxes) {
                        console.error(
                            `Error: secondaryAxis is not set in dataProperties but referenced in seriesProperties (key=${line.key})`
                        )
                    }

                    const enableSecondaryAxis =
                        line.secondaryAxis && hasMultipleAxes

                    const yAxisId = enableSecondaryAxis
                        ? 'secondaryAxis'
                        : 'primaryAxis'

                    const unit = enableSecondaryAxis
                        ? dataProperties.secondaryAxis!.format
                        : dataProperties.format

                    return (
                        <Line
                            key={line.key}
                            dot={false}
                            yAxisId={yAxisId}
                            unit={unit}
                            dataKey={line.key}
                            stroke={line.color?.toLowerCase()}
                            activeDot={CustomActiveDot}
                            isAnimationActive={false}
                            strokeWidth={line.strokewidth || 2}
                            strokeDasharray={
                                lineTypes[line.linetype || 'solid']
                            }
                        />
                    )
                })}
            </CustomLineChart>
        </>
    )
}
