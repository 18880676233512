import { NyuLineProperties } from '../../types'

export default function DataKeyLabel({
    lineData,
}: {
    lineData: NyuLineProperties
}) {
    const { label, color } = lineData
    return (
        <>
            <span className="mr-2 inline-flex w-3 justify-center">
                <div className="size-3" style={{ background: color! }} />
            </span>
            {label!}
        </>
    )
}
