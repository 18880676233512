import { useEffect, useState } from 'react'
import { cn } from '../utils/cn'

export default function TogglePanel({
    title,
    initialOpen,
    children,
    info,
}: {
    title: string
    initialOpen: boolean
    children: React.ReactNode
    info: React.ReactNode
}) {
    const [open, setOpen] = useState(initialOpen)

    useEffect(() => {
        setOpen(initialOpen)
    }, [initialOpen])

    return (
        <div className="mt-4 rounded-md border">
            <div
                className="relative flex cursor-pointer select-none bg-gray-200 p-2"
                onClick={() => setOpen(!open)}
            >
                <span
                    className={cn(
                        'absolute left-3 top-1/2 transform -translate-y-1/2',
                        open ? '' : '-rotate-90'
                    )}
                >
                    <div className="size-0 border-x-4 border-t-4 border-transparent border-t-gray-800"></div>
                </span>
                <span className="ml-5">{title}</span>
                {info && <span className="ml-auto text-gray-400">{info}</span>}
            </div>
            {open && <div className="p-2">{children}</div>}
        </div>
    )
}
