import { useMemo } from 'react'
import { useAsync } from 'react-use'
import { Log } from './useLog'

export type AsyncPipelineStepFunction = (input: any, log: Log) => Promise<any>

type Step = [AsyncPipelineStepFunction, string]

export type Pipeline = Step[]

export type Pipelines = Pipeline[]

const maxErrorMessageLength = 10000

const processNextStep = async (
    steps: Pipeline,
    log: Log,
    lastResult: any = null
) => {
    const [step, ...rest] = steps

    if (!step) {
        return lastResult
    }

    const [fn, name] = step
    try {
        const result = await fn(lastResult, log)
        log(name + ' - success: ' + typeof result)
        return processNextStep(rest, log, result)
    } catch (error) {
        log(name + ' - error:')
        const errorMessage = String(error)
        const truncatedErrorMessage = errorMessage.slice(
            0,
            maxErrorMessageLength
        )
        log(truncatedErrorMessage)
        throw error
    }
}

const processNextPipeline = async (pipelines: Pipelines, log: Log) => {
    const [pipeline, ...rest] = pipelines

    if (!pipeline) {
        const msg = 'FATAL error, no valid data sources found'
        log(msg)
        throw new Error(msg)
    }

    try {
        const data = await processNextStep(pipeline, log)
        return data
    } catch (error) {
        console.log('error', error)
        return processNextPipeline(rest, log)
    }
}

export default function usePipelines(
    pipelines: Pipelines,
    log: Log
): ReturnType<typeof useAsync> {
    const pipelineFunction = useMemo(() => {
        if (pipelines.length === 0) {
            return async () => null
        }

        return async () => processNextPipeline(pipelines, log)
    }, [pipelines, log])

    return useAsync(pipelineFunction, [pipelines])
}
