import Dropdown, { DropdownProps } from './Dropdown'

export default function Filter({
    startYearDropdownProps,
    endYearDropdownProps,
}: {
    startYearDropdownProps: DropdownProps
    endYearDropdownProps: DropdownProps
}) {
    return (
        <>
            <Dropdown
                label="Start Year"
                className="w-24 text-left"
                testId="start-year-dropdown"
                {...startYearDropdownProps}
            />
            <Dropdown
                label="End Year"
                className="w-24 text-left"
                testId="end-year-dropdown"
                {...endYearDropdownProps}
            />
        </>
    )
}
