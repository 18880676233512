import { z, ZodTypeAny } from 'zod'

const nyuDataPointValueSchema = z.union([z.number(), z.string()])

const nyuTimeValueSchema = z.number().int()

const nyuDataPointSchema = z
    .object({
        time: nyuTimeValueSchema,
    })
    .catchall(nyuDataPointValueSchema)

const nyuDataPointsSchema = z.array(nyuDataPointSchema)

const nyuLinePropertiesSchema = z.object({
    key: z.string(),
    group: z.string(),
    label: z.string(),
    color: z.string().optional(),
    linetype: z.enum(['solid', 'dotted']).optional(),
    strokewidth: z.number().int().optional(),
    secondaryAxis: z.boolean().optional(),
})

const nyuSeriesPropertiesSchema = z.array(nyuLinePropertiesSchema)

const nyuWrappedStringSchema = z.union([z.string(), z.array(z.string())])
const nyuStringOrEmptyObject = z.union([z.string(), z.object({})])

const nyuDataPropertiesSchema = z.object({
    format: z.string(),
    timeFormat: z.enum(['y', 'q', 'm']).optional(),
    axisTitle: z.string(),
    source: nyuWrappedStringSchema,
    axisDomain: z
        .array(z.union([z.number(), z.enum(['auto'])]))
        .length(2)
        .optional(),
    note: nyuStringOrEmptyObject.optional(),
    secondaryAxis: z
        .object({
            format: z.string(),
            axisTitle: z.string(),
            axisDomain: z
                .array(z.union([z.number(), z.enum(['auto'])]))
                .length(2)
                .optional(),
        })
        .optional(),
    hideControls: z
        .array(z.enum(['filter', 'download', 'selector', 'all']))
        .optional(),
})

const nyuSelectizeProperties = z
    .object({
        options: z.array(z.string()),
        isDefault: z.boolean().optional(),
    })
    .strict()

export const nyuGraphDataSchema = z
    .object({
        dataProperties: nyuDataPropertiesSchema,
        seriesProperties: nyuSeriesPropertiesSchema,
        selectizeProperties: nyuSelectizeProperties.optional(),
        data: nyuDataPointsSchema,
    })
    .strict()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const prohibitEmptyObjects = (data: any) => {
    const invalidKeys = Object.entries(data)
        .filter(([, value]) => Object.keys(value!).length === 0)
        .map(([key]) => key)

    if (invalidKeys.length > 0) {
        throw new z.ZodError([
            {
                code: z.ZodIssueCode.custom,
                message:
                    'All leaf nodes must be of type `nyuGraphDataSchema`, maybe there is an empty object ({}) in `dataset`',
                path: invalidKeys,
            },
        ])
    }

    return true
}

const recordUnion = (children: [ZodTypeAny, ZodTypeAny, ...ZodTypeAny[]]) =>
    z.record(z.string(), z.union(children)).refine(prohibitEmptyObjects)

const nyuDataSetSchema = z.union([
    // 0 levels
    nyuGraphDataSchema,

    // 0 or 1 level
    recordUnion([
        nyuGraphDataSchema, // level 0
        z.record(z.string(), nyuGraphDataSchema), // level 1
    ]),

    // 0, 1 or 2 levels
    recordUnion([
        nyuGraphDataSchema, // level 0
        recordUnion([
            nyuGraphDataSchema, // level 1
            z.record(z.string(), nyuGraphDataSchema), // level 2
        ]),
    ]),

    // 0, 1, 2 or 3 levels
    recordUnion([
        nyuGraphDataSchema, // level 0
        recordUnion([
            nyuGraphDataSchema, // level 1
            recordUnion([
                nyuGraphDataSchema, // level 2
                z.record(z.string(), nyuGraphDataSchema), // level 3
            ]),
        ]),
    ]),
])

const nyuUiPropertiesSchema = z
    .object({
        firstSelectorAsTabs: z.boolean().optional(),
        chartHeight: z
            .object({
                value: z.number().int(),
                unit: z.enum(['%', 'px']),
            })
            .strict()
            .optional(),
    })
    .strict()
    .optional()

export const nyuDataSchema = z.object({
    meta: z.object({
        title: z.string(),
        schema: z.enum(['switch', 'selectize']),
        version: z.string(),
        templateId: z.number().int(),
        cite: z.string().optional(),
    }),
    uiProperties: nyuUiPropertiesSchema,
    dataset: nyuDataSetSchema,
})

export type NyuDataPoint = z.infer<typeof nyuDataPointSchema>

export type NyuDataPoints = z.infer<typeof nyuDataPointsSchema>

export type NyuDataSet = z.infer<typeof nyuDataSetSchema>

export type NyuGraphData = z.infer<typeof nyuGraphDataSchema>

export type NyuData = z.infer<typeof nyuDataSchema>

export type NyuTemplateId = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

export type NyuLineProperties = z.infer<typeof nyuLinePropertiesSchema>

export type NyuGroupProperties = {
    id: string
    firstLine: NyuLineProperties
    lines: NyuLineProperties[]
}

export type NyuSeriesProperties = z.infer<typeof nyuSeriesPropertiesSchema>

export type NyuDataProperties = z.infer<typeof nyuDataPropertiesSchema>

export type NyuUiProperties = z.infer<typeof nyuUiPropertiesSchema>
