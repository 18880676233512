import useGlobalState from '../hooks/useGlobalState'

import useIsDevelopmentDomain from '../hooks/useIsDevelopmentDomain'
import DataFileUploadButton from './DataFileUploadButton'
import Dropdown from './Dropdown'
import Input from './Input'

function Item({
    label,
    children,
    disabled = false,
}: {
    label: string
    children: React.ReactNode
    disabled?: boolean
}) {
    return (
        <div
            className={`py-1 ${disabled ? 'pointer-events-none select-none opacity-20' : ''} inline-flex items-center`}
        >
            <div className="inline-flex min-w-32">{label}:&nbsp;</div>
            <div className="inline-flex grow">{children}</div>
        </div>
    )
}

const dataUrls = [
    '/static/1_gci.json',
    '/static/2_components.json',
    '/static/3_incomesector.json',
    '/static/4_shareshifts.json',
    '/static/5_blocs.json',
    '/static/6_geopolitical.json',
    '/static/7_regionalization.json',
    '/static/8_diversification.json',
]

export default function Debugger() {
    const dataUrl = useGlobalState((state) => state.dataUrl)
    const dataVersion = useGlobalState((state) => state.dataVersion)
    const setState = useGlobalState((state) => state.setState)
    const setDataUrl = (dataUrl: string) => setState({ dataUrl })

    const isDev = useIsDevelopmentDomain()

    return (
        <div className="mb-4 flex flex-col gap-4">
            {isDev && (
                <>
                    <Item label="Data URL">
                        <Dropdown
                            className="text-left"
                            value={dataUrl || ''}
                            options={dataUrls}
                            setValue={setDataUrl}
                            testId="data-url-selector"
                        />
                    </Item>
                    <Item label="Upload data file">
                        <DataFileUploadButton />
                    </Item>
                </>
            )}
            {!isDev && (
                <Item label="Data URL">
                    <Input value={dataUrl || ''} setValue={setDataUrl} />
                </Item>
            )}
            <Item label="Data Version">{dataVersion || '(not set)'}</Item>
        </div>
    )
}
