import { ReactNode } from 'react'

export default function CustomLabel({
    children,
    chartWidth,
    chartHeight,
    side = 'left',
}: {
    children: ReactNode
    chartWidth: number
    chartHeight: number
    side: 'left' | 'right'
}) {
    const labelWidth = chartHeight - 75
    const rotate = side === 'left' ? -90 : 90
    const x = side === 'left' ? 0 : chartWidth
    const y = side === 'left' ? labelWidth : 0
    return (
        <g transform={`translate(${x},${y}) rotate(${rotate})`}>
            <foreignObject width={labelWidth} height={40}>
                <div className="flex size-full items-center justify-center whitespace-pre-wrap text-center">
                    {children}
                </div>
            </foreignObject>
        </g>
    )
}
