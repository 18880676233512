import { createContext, useRef } from 'react'
import { createStore } from 'zustand'

type GlobalStateValues = {
    dataUrl: string
    buildTime: string
    container: HTMLElement | null
    downloadView: boolean
    templateNode: HTMLElement | null
    dataVersion?: string
    log: string[]
}

export type GlobalState = GlobalStateValues & {
    addToLog: (msg: string) => void
    clearLog: () => void
    setState: (mergeState: Partial<GlobalStateValues>) => void
}

const defaultProps = {
    dataUrl: '',
    buildTime: '',
    container: null,
    downloadView: false,
    templateNode: null,
    log: [],
}

type GlobalStore = ReturnType<typeof createGlobalStore>

const createGlobalStore = (initProps?: Partial<GlobalState>) =>
    createStore<GlobalState>()((set) => ({
        ...defaultProps,
        ...initProps,
        addToLog: (msg: string) =>
            set((state) => ({ log: [...state.log, msg] })),
        clearLog: () => set({ log: [''] }),
        setState: (mergeState) => set(mergeState),
    }))

export const GlobalContext = createContext<GlobalStore | null>(null)

export default GlobalContext

export const withGlobalContextProvider =
    (Component: React.ComponentType<any>) => (props: any) => {
        const store = useRef<GlobalStore>(createGlobalStore()).current
        return (
            <GlobalContext.Provider value={store}>
                <Component {...props} />
            </GlobalContext.Provider>
        )
    }
