import { useEffect, useState } from 'react'

export default function Input({
    value: initialValue,
    setValue,
}: {
    value: string
    setValue: (value: string) => void
}) {
    const [localValue, setLocalValue] = useState(initialValue || '')

    useEffect(() => {
        setLocalValue(initialValue)
    }, [initialValue])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        console.log('Input.tsx: handleSubmit, localValue=' + localValue)
        event.preventDefault()
        setValue(localValue)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setLocalValue(event.target.value)

    return (
        <form onSubmit={handleSubmit}>
            <input
                value={localValue}
                name="input"
                className="w-full border-2 border-gray-800 p-1"
                onChange={handleChange}
            />
        </form>
    )
}
