import { useCallback } from 'react'
import useGlobalState from './useGlobalState'

function getTimeString() {
    const opts = { timeZone: 'Europe/Berlin' }
    const time = new Date().toLocaleTimeString('de-DE', opts).slice(0, 8)
    return time
}

export type Log = (msg: string) => void

export default function useLog(): [Log, () => void] {
    const clearLog = useGlobalState((state) => state.clearLog)
    const addToLog = useGlobalState((state) => state.addToLog)

    const log = useCallback(
        (msg: string) => {
            addToLog(`[${getTimeString()}] ${msg}`)
        },
        [addToLog]
    )
    return [log, clearLog]
}
