import { DotProps } from 'recharts'

export default function CustomActiveDot({ cx, cy, fill }: DotProps) {
    if (cx === null || cy === null) {
        // return null leads to a type warning from recharts
        return <g />
    }

    return (
        <g transform={`translate(${cx},${cy})`}>
            <circle r={8} fill={fill} opacity={0.2} />
            <circle r={4.5} fill="white" />
            <circle r={4} fill={fill} stroke="white" strokeWidth={2} />
        </g>
    )
}
