import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { NyuDataPoints } from '../types'
import { getStartEndYearOptionsFromYears } from '../utils/helpers'

function useKeepEdgeYear(
    dataPoints: NyuDataPoints,
    edgeYear: string
): [string, Dispatch<SetStateAction<string>>] {
    const [year, setYear] = useState(edgeYear)

    const [yearIsEdgeYear, setYearIsEdgeYear] = useState(year === edgeYear)

    useEffect(() => {
        setYearIsEdgeYear(year === edgeYear)
    }, [year, dataPoints])

    useEffect(() => {
        if (yearIsEdgeYear) {
            setYear(edgeYear)
        }
    }, [dataPoints])

    const actualYear = yearIsEdgeYear ? edgeYear : year
    return [actualYear, setYear]
}

export default function useFilterYears(dataPoints: NyuDataPoints) {
    const allTimes = useMemo(() => {
        return dataPoints.map(({ time }) => time)
    }, [dataPoints])

    const { startOptions, endOptions } = useMemo(
        () => getStartEndYearOptionsFromYears(allTimes),
        [allTimes]
    )
    const firstStartYear = startOptions[0]
    const lastEndYear = endOptions[endOptions.length - 1]
    const [startYear, setStartYear] = useKeepEdgeYear(
        dataPoints,
        firstStartYear
    )
    const [endYear, setEndYear] = useKeepEdgeYear(dataPoints, lastEndYear)

    const handleSetStartYear = (year: string) => {
        setStartYear(year)
        const validEndYear = Number(year) + 1
        if (Number(endYear) < validEndYear) {
            setEndYear(String(validEndYear))
        }
    }

    const handleSetEndYear = (year: string) => {
        setEndYear(year)
        const validStartYear = Number(year) - 1
        if (Number(startYear) > validStartYear) {
            setStartYear(String(validStartYear))
        }
    }

    const filteredDataPoints = useMemo(() => {
        const startYearNum = Number(startYear)
        const endYearNum = Number(endYear)
        const filteredDataPoints = dataPoints.filter(
            ({ time }: { time: number }) =>
                time >= startYearNum && time <= endYearNum
        )
        const sortedDataPoints = filteredDataPoints.toSorted(
            ({ time: a }, { time: b }) => a - b
        )
        return sortedDataPoints
    }, [dataPoints, startYear, endYear])

    return useMemo(
        () => ({
            dataPoints: filteredDataPoints,
            startYearDropdownProps: {
                value: startYear,
                options: startOptions,
                setValue: handleSetStartYear,
            },
            endYearDropdownProps: {
                value: endYear,
                options: endOptions,
                setValue: handleSetEndYear,
            },
        }),
        [filteredDataPoints, startYear, endYear, startOptions, endOptions]
    )
}
