import { useMemo } from 'react'
import { Line } from 'recharts'
import useFilterYears from '../../hooks/useFilterYears'
import useToggleState from '../../hooks/useToggleState'
import {
    NyuData,
    NyuDataPoints,
    NyuGraphData,
    NyuSeriesProperties,
} from '../../types'
import { getGroupsFromSeriesProperties } from '../../utils/helpers'
import { lineTypes } from '../../utils/options'
import CustomLineChart from '../CustomLineChart'
import CustomActiveDot from '../Graph/CustomActiveDot'
import Header from '../Header'
import SwitchButton from '../SwitchButton'
import useGlobalState from '../../hooks/useGlobalState'

export default function Page1({ data }: { data: NyuData }) {
    const {
        meta: { title },
        dataset,
        uiProperties,
    } = data

    const downloadView = useGlobalState((state) => state.downloadView)

    // TODO: Expecting dataset to be not nested, potentially unsafe
    const {
        dataProperties,
        seriesProperties,
        data: allDataPoints,
    } = dataset as NyuGraphData

    const { dataPoints, startYearDropdownProps, endYearDropdownProps } =
        useFilterYears(allDataPoints as NyuDataPoints)

    const groups = useMemo(() => {
        return getGroupsFromSeriesProperties(
            seriesProperties as NyuSeriesProperties
        )
    }, [seriesProperties])

    // active groups
    const allGroupIds = groups.map(({ id }) => id)
    const [activeGroupIds, toggleActiveGroupId] = useToggleState(
        new Set(allGroupIds)
    )
    const activeGroups = groups.filter(({ id }) => activeGroupIds.has(id))
    const lines = activeGroups.flatMap(({ lines }) => lines)

    const hasMultipleAxes = !!dataProperties.secondaryAxis

    return (
        <>
            <div className="mb-2 flex flex-wrap items-start justify-between gap-2">
                <div className="text-xl font-bold" data-testid="headline">
                    {title}
                </div>
                {downloadView && (
                    <div className="text-right font-bold">
                        DHL Global Connectedness Tracker
                    </div>
                )}
            </div>
            <div className="mb-2 flex flex-wrap items-start justify-between gap-2">
                {downloadView && (
                    <div className="mt-1 inline-flex gap-1 whitespace-nowrap md:mt-0">
                        {groups.map((group, index) => (
                            <div
                                key={`${group}_${index}_download`}
                                className="group"
                            >
                                <span className="text-gray-500">
                                    {group.firstLine.label}
                                </span>
                                <span className="text-gray-500 group-last:hidden">
                                    ,
                                </span>
                            </div>
                        ))}
                    </div>
                )}
                {!downloadView && (
                    <div className="grid grid-cols-1 gap-4 xs:grid-cols-2 lg:grid-cols-3">
                        {groups.map((group, index) => (
                            <SwitchButton
                                key={`${index}_${group.id}`}
                                id={`${index}_${group.id}`}
                                label={group.firstLine.label}
                                checked={activeGroupIds.has(group.id)}
                                disabled={
                                    activeGroupIds.size === 1 &&
                                    activeGroupIds.has(group.id)
                                }
                                onCheckedChange={() =>
                                    toggleActiveGroupId(group.id)
                                }
                                checkedColor={group.firstLine.color}
                            />
                        ))}
                    </div>
                )}
                <div className="w-full items-end md:w-auto">
                    <Header
                        title={title}
                        dataPoints={dataPoints}
                        dataProperties={dataProperties}
                        seriesProperties={seriesProperties}
                        uiProperties={uiProperties}
                        activeDataPointKeys={Array.from(activeGroupIds)}
                        startYearDropdownProps={startYearDropdownProps}
                        endYearDropdownProps={endYearDropdownProps}
                        cite={data.meta.cite}
                    />
                </div>
            </div>
            <CustomLineChart
                dataPoints={dataPoints}
                dataProperties={dataProperties}
                seriesProperties={seriesProperties}
                uiProperties={uiProperties}
            >
                {lines.map((line) => {
                    if (line.secondaryAxis && !hasMultipleAxes) {
                        console.error(
                            `Error: secondaryAxis is not set in dataProperties but referenced in seriesProperties (key=${line.key})`
                        )
                    }

                    const enableSecondaryAxis =
                        line.secondaryAxis && hasMultipleAxes

                    const yAxisId = enableSecondaryAxis
                        ? 'secondaryAxis'
                        : 'primaryAxis'

                    const unit = enableSecondaryAxis
                        ? dataProperties.secondaryAxis!.format
                        : dataProperties.format

                    return (
                        <Line
                            key={line.key}
                            dot={false}
                            yAxisId={yAxisId}
                            unit={unit}
                            dataKey={line.key}
                            stroke={line.color?.toLowerCase()}
                            activeDot={CustomActiveDot}
                            isAnimationActive={false}
                            strokeWidth={line.strokewidth || 2}
                            strokeDasharray={
                                lineTypes[line.linetype || 'solid']
                            }
                        />
                    )
                })}
            </CustomLineChart>
        </>
    )
}
