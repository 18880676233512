import * as Switch from '@radix-ui/react-switch'
import { cn } from '../utils/cn'

const uncheckedColor = '#f2f2f2'
const defaultCheckedColor = '#b7bbce'

const SwitchButton = ({
    label,
    id,
    checked,
    onCheckedChange,
    className = '',
    checkedColor = defaultCheckedColor,
    disabled = false,
}: {
    label: string
    id: string
    checked: boolean
    onCheckedChange: (checked: boolean) => void
    className?: string
    checkedColor?: string
    disabled?: boolean
}) => {
    return (
        <div className={cn('flex items-center gap-2', className)}>
            <Switch.Root
                className={cn(
                    'relative h-6 w-11 shrink-0 grow-0 cursor-pointer rounded-xl outline-none data-[disabled]:cursor-not-allowed'
                )}
                id={id}
                style={{
                    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
                    boxShadow: 'inset  0 0 0 0.0625rem rgba(0,0,0,0.3)',
                    backgroundColor: checked ? checkedColor : uncheckedColor,
                }}
                checked={checked}
                disabled={disabled}
                onCheckedChange={onCheckedChange}
                aria-label={label}
            >
                <Switch.Thumb
                    style={{
                        boxShadow:
                            '0 0.0625rem 0.125rem 0 rgba(0,0,0,0.15), 0 0 0.25rem 0 rgba(0,0,0,0.15)',
                    }}
                    className="block size-[22px] translate-x-0.5 rounded-full bg-white transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[21px]"
                />
            </Switch.Root>
            <label className="pr-[15px] text-sm leading-none" htmlFor={id}>
                {label}
            </label>
        </div>
    )
}

export default SwitchButton
