export const appVersion: string = 'v1.1'

export const trendLabels: {
    [key: string]: string
} = {
    global: 'Global Connectedness',
    people: 'People',
    capital: 'Capital',
    information: 'Information',
    trade: 'Trade',
}

export const trendLabelsToIds: {
    [key: string]: 'global' | 'people' | 'capital' | 'information' | 'trade'
} = {
    'Global Connectedness': 'global',
    People: 'people',
    Capital: 'capital',
    Information: 'information',
    Trade: 'trade',
}

export const lineTypes: Record<string, string | undefined> = {
    solid: undefined,
    dotted: '4 4',
}
