import { createRoot } from 'react-dom/client'
import App from './comps/App'
import './custom_base.css'
import { isDevelopmentDomain } from './utils/helpers'

// import ALL the CSS variables
// import '@dhl-official/tokens/base.css'
// import { defineCustomElements } from '@dhl-official/react-library'

function readInfo<T>(container: HTMLElement, key: string, defaultValue: T) {
    try {
        return container.dataset[key] || defaultValue
    } catch {
        return defaultValue
    }
}

function embeddApp(container: HTMLElement) {
    const buildTime = readInfo(container, 'buildTime', '(dev)')

    let dataUrl = null
    if (isDevelopmentDomain()) {
        dataUrl = '/static/1_gci.json'
    } else {
        const dataUrlDomNode = container.closest<HTMLElement>('[data-data-url]')
        if (dataUrlDomNode) {
            dataUrl = readInfo(dataUrlDomNode, 'dataUrl', null)
        }
    }

    if (!dataUrl) {
        const msg = 'GCT: attribute data-data-url is empty'
        console.error(msg)
    } else {
        console.log('GCT: dataUrl', dataUrl)
    }

    const root = createRoot(container)
    root.render(<App buildTime={buildTime} dataUrl={dataUrl!} />)
}

function handleDOMContentLoaded() {
    // TODO: check if this works on all browsers
    const targets = Array.from(
        document.querySelectorAll<HTMLElement>(
            '.gctClient-app:not([data-gct-embedded])'
        )
    )
    targets.forEach((container: HTMLElement) => {
        container.setAttribute('data-gct-embedded', '1')
        embeddApp(container)
    })
}

window.addEventListener('DOMContentLoaded', handleDOMContentLoaded)
