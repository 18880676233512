import { createBreakpoint } from 'react-use'
import screens from '../screens.json'

type BreakpointName = keyof typeof screens

const useGetBreakpoint = createBreakpoint({ _: 0, ...screens })

export default function useBreakpoint(breakpoint: BreakpointName) {
    const currentBreakpoint = useGetBreakpoint() as BreakpointName

    const isBreakpointOrHigher =
        screens[breakpoint] <= screens[currentBreakpoint]

    return isBreakpointOrHigher
}
