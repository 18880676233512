import { useCallback, useState } from 'react'
import {
    CartesianGrid,
    Legend,
    LegendProps,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts'
import { AxisDomain } from 'recharts/types/util/types'
import useBreakpoint from '../hooks/useBreakpoint'
import useGlobalState from '../hooks/useGlobalState'
import {
    NyuDataPoints,
    NyuDataProperties,
    NyuSeriesProperties,
    NyuUiProperties,
} from '../types'
import { formatDataPointValue, unwrapValueInArray } from '../utils/helpers'
import CustomLabel from './Graph/CustomLabel'
import CustomLegend from './Graph/CustomLegend'
import CustomTooltip from './Graph/CustomTooltip'
import { cn } from '../utils/cn'

function formatYearWithPadding(time: number) {
    return ` '${time.toString().slice(-2)} `
}

const trackerURL = 'dhl.com/globalconnectedness'

const defaultChartHeight = 500
const minChartHeight = 300

export default function CustomLineChart({
    dataPoints,
    dataProperties,
    seriesProperties,
    uiProperties,
    children = null,
}: {
    dataPoints: NyuDataPoints
    dataProperties: NyuDataProperties
    seriesProperties: NyuSeriesProperties
    uiProperties: NyuUiProperties
    children: React.ReactNode
}) {
    const downloadView = useGlobalState((state) => state.downloadView)

    const renderTooltip = useCallback(
        (props: TooltipProps<number, string>) => (
            <CustomTooltip
                {...props}
                seriesProperties={seriesProperties}
                dataProperties={dataProperties}
            />
        ),
        [seriesProperties, dataProperties]
    )

    const renderLegend = useCallback(
        (props: LegendProps) => (
            <CustomLegend {...props} seriesProperties={seriesProperties} />
        ),
        [seriesProperties]
    )

    const source = unwrapValueInArray(dataProperties.source)
    const note = unwrapValueInArray(dataProperties.note)

    const hasMultipleAxes = !!dataProperties.secondaryAxis
    const rightMargin = hasMultipleAxes ? 40 : 20

    const breakpointSmOrLarger = useBreakpoint('sm')
    const breakpointMdOrLarger = useBreakpoint('md')
    const axisLabelOnChart = breakpointSmOrLarger

    const [chartWidth, setChartWidth] = useState(0)

    const calculateChartHeightFromWidth = (chartWidth: number) => {
        if (chartWidth < 500) {
            return chartWidth * 0.9
        }

        const unit = uiProperties?.chartHeight?.unit || 'px'
        if (unit === 'px') {
            return uiProperties?.chartHeight?.value
        }

        const percentage = uiProperties?.chartHeight?.value || 30
        return (chartWidth * percentage) / 100
    }

    const calculateChartHeight = (chartWidth: number) =>
        Math.max(
            minChartHeight,
            calculateChartHeightFromWidth(chartWidth) || defaultChartHeight
        )

    const [chartHeight, setChartHeight] = useState(
        calculateChartHeight(chartWidth)
    )

    const handleResize = (nextChartWidth: number) => {
        setChartWidth(nextChartWidth)
        setChartHeight(calculateChartHeight(nextChartWidth))
    }

    const leftLabel = (
        <CustomLabel
            chartWidth={chartWidth}
            chartHeight={chartHeight}
            side="left"
        >
            {dataProperties.axisTitle}
        </CustomLabel>
    )

    const rightLabel = (
        <CustomLabel
            chartWidth={chartWidth}
            chartHeight={chartHeight}
            side="right"
        >
            {dataProperties.secondaryAxis?.axisTitle}
        </CustomLabel>
    )

    const domain = (dataProperties.axisDomain as AxisDomain) || [0, 'auto']

    return (
        <div className="mt-4 bg-white">
            {!axisLabelOnChart && (
                <div
                    className={cn(
                        'flex gap-4 flex-wrap my-2 items-center',
                        hasMultipleAxes ? 'justify-between' : 'justify-center'
                    )}
                >
                    <>
                        <p
                            className={
                                hasMultipleAxes
                                    ? 'grow text-left'
                                    : 'text-center'
                            }
                        >
                            {dataProperties.axisTitle}
                        </p>
                        {hasMultipleAxes && (
                            <p className="grow text-right">
                                {dataProperties.secondaryAxis?.axisTitle}
                            </p>
                        )}
                    </>
                </div>
            )}
            <ResponsiveContainer height={chartHeight} onResize={handleResize}>
                <LineChart
                    data={dataPoints}
                    margin={{
                        left: axisLabelOnChart ? 35 : 0,
                        right: axisLabelOnChart ? rightMargin : 0,
                    }}
                >
                    <CartesianGrid vertical={false} />
                    <XAxis
                        dataKey="time"
                        tickFormatter={() => ''}
                        tickLine
                        interval={0}
                        height={5}
                    />
                    <XAxis
                        dataKey="time"
                        tickFormatter={formatYearWithPadding}
                        tickLine
                        axisLine={false}
                        height={20}
                        xAxisId="time_with_interval"
                    />
                    <YAxis
                        yAxisId="primaryAxis"
                        orientation="left"
                        label={axisLabelOnChart ? leftLabel : undefined}
                        domain={domain}
                        tickFormatter={(value) =>
                            formatDataPointValue(value, dataProperties.format)
                        }
                    />
                    {hasMultipleAxes && (
                        <YAxis
                            yAxisId="secondaryAxis"
                            orientation="right"
                            label={axisLabelOnChart ? rightLabel : undefined}
                            domain={domain}
                            tickFormatter={(value) =>
                                formatDataPointValue(
                                    value,
                                    dataProperties.secondaryAxis!.format
                                )
                            }
                        />
                    )}
                    {breakpointMdOrLarger && (
                        <Tooltip content={renderTooltip} />
                    )}
                    {children}
                    <Legend
                        /* @ts-expect-error: Find correct implementation */
                        content={renderLegend}
                        wrapperStyle={{ left: 0, width: '100%' }}
                    />
                </LineChart>
            </ResponsiveContainer>
            <div
                className={cn(
                    'flex justify-between gap-2 flex-col md:flex-row items-start md:items-center'
                )}
            >
                <div className="space-y-2">
                    {source && (
                        <p>
                            <span className="font-bold">Data Source: </span>
                            {source}
                        </p>
                    )}
                    {note && (
                        <p>
                            <span className="font-bold">Note: </span>
                            {note}
                        </p>
                    )}
                </div>
                {downloadView && (
                    <div className="text-gray-500">{trackerURL}</div>
                )}
            </div>
        </div>
    )
}
