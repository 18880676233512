import useGlobalState from '../hooks/useGlobalState'

export default function DataFileUploadButton() {
    const setState = useGlobalState((state) => state.setState)
    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.target.files?.[0]
        if (!file) {
            return
        }

        const nextDataUrl = URL.createObjectURL(file)
        setState({ dataUrl: nextDataUrl })
    }

    return (
        <>
            <label
                className="cursor-pointer rounded border border-gray-300 px-2 py-1 leading-loose"
                htmlFor="data-file"
            >
                Upload
            </label>
            <input
                id="data-file"
                type="file"
                className="hidden"
                onChange={handleFileChange}
            />
        </>
    )
}
