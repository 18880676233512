import * as Tabs from '@radix-ui/react-tabs'
import { Dispatch, SetStateAction } from 'react'
import { cn } from '../utils/cn'
import CustomIcon from '../assets/icon_custom.svg'
import { isCustomOption } from '../utils/helpers'

export default function TabButtons({
    value,
    options,
    setValue,
    testId = null,
}: {
    value?: string
    options: string[]
    setValue: Dispatch<SetStateAction<string>> | ((value: string) => void)
    testId?: string | null
}) {
    const handleValueChange = (value: string) => {
        setValue(value)
    }

    return (
        <Tabs.Root
            orientation="horizontal"
            value={value}
            onValueChange={handleValueChange}
        >
            <Tabs.List
                aria-label=""
                className="mt-2 flex flex-wrap text-center text-base font-bold"
                data-testid={testId}
            >
                {options.map((option) => {
                    const active = value === option
                    const className = cn(
                        'flex grow p-4 border-t-4 py-[14px] px-[24px] sm:py-[22px] justify-center sm:px-[32px] border-transparent cursor-pointer',
                        active && 'bg-white border-[#d40511] active',
                        !active && 'bg-[#ebebeb]'
                    )
                    const isCustom = isCustomOption(option)
                    return (
                        <Tabs.Trigger
                            className={className}
                            key={option}
                            value={option}
                            data-testid={`${testId}-option-${option}`}
                        >
                            {isCustom && (
                                <img
                                    src={CustomIcon}
                                    className="mx-2 -mt-0.5 inline-block size-6"
                                />
                            )}
                            {option}
                        </Tabs.Trigger>
                    )
                })}
            </Tabs.List>
        </Tabs.Root>
    )
}
