import { useContext } from 'react'
import { useStore } from 'zustand'
import GlobalContext, { GlobalState } from '../comps/GlobalContext'

type Selector<T> = (state: GlobalState) => T

export default function useGlobalState<T>(selector: Selector<T>): T {
    const store = useContext(GlobalContext)
    if (!store) {
        throw new Error(
            'useGlobalState must be used within a GlobalContext.Provider'
        )
    }
    const state = useStore(store, selector)
    return state
}
