import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {
    Dispatch,
    SetStateAction,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'
import useGlobalState from '../hooks/useGlobalState'
import { cn } from '../utils/cn'

export type DropdownProps = {
    value?: string
    options: string[]
    label?: string
    setValue: Dispatch<SetStateAction<string>> | ((value: string) => void)
    className?: string
    testId?: string | null
    isCombobox?: boolean
}

export default function Dropdown({
    value,
    options,
    label,
    setValue,
    className = '',
    testId = null,
    isCombobox = false,
}: DropdownProps) {
    const [searchString, setSearchString] = useState('')
    const container = useGlobalState((state) => state.container)
    const activeItemRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)

    useLayoutEffect(() => {
        if (!open || !activeItemRef.current) {
            return
        }
        activeItemRef.current.scrollIntoView?.({
            block: 'center',
            behavior: 'auto',
        })
        activeItemRef.current.focus()
    }, [open])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.currentTarget.value)
    }

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'ArrowDown') {
            e.stopPropagation()
            return
        }

        const target = e.target as HTMLElement
        const nextSibling = target.nextSibling as HTMLElement
        if (nextSibling) {
            nextSibling.focus()
        }
    }

    const handleContentKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const target = e.target as HTMLElement
        if (target.dataset.index === '0' && e.key === 'ArrowUp') {
            const previousSibling = target.previousElementSibling as HTMLElement
            if (previousSibling) {
                previousSibling.focus()
            }
        }
    }

    const handleOpenChange = (nextOpen: boolean) => {
        setOpen(nextOpen)
        setSearchString('')
    }

    const labelClasses = label ? 'relative pt-4' : ''
    const filteredOptions = options.filter((option) =>
        option.toLowerCase().includes(searchString.toLowerCase())
    )

    return (
        <DropdownMenu.Root onOpenChange={handleOpenChange} modal={false}>
            <DropdownMenu.Trigger asChild>
                <button
                    className={cn(
                        'bg-white',
                        'h-12 max-w-sm',
                        'inline-flex w-full items-center',
                        'relative',
                        'group',
                        'select-none',
                        'outline-none',
                        className,
                        labelClasses
                    )}
                    data-testid={testId}
                    aria-label={label}
                >
                    <div
                        className={cn(
                            'absolute',
                            'inset-0',
                            'border',
                            'border-[#8c8c8c]',
                            'rounded',
                            'pointer-events-none',
                            'group-focus:border-2',
                            'group-focus:border-black',
                            'group-[[data-state=open]]:border-2',
                            'group-[[data-state=open]]:border-b-0',
                            'group-[[data-state=open]]:border-black',
                            'group-[[data-state=open]]:rounded-b-none'
                        )}
                    ></div>
                    <div className="absolute flex h-12 w-full grow p-3">
                        <span className="mt-1 inline-block w-full truncate">
                            {value}
                        </span>
                    </div>
                    {label && (
                        <label className="absolute left-3 top-2 text-[10px] text-black">
                            {label}
                        </label>
                    )}
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal container={container}>
                <DropdownMenu.Content
                    avoidCollisions={false}
                    onKeyDown={handleContentKeyDown}
                    className={cn(
                        'overflow-hidden rounded border-2 border-black bg-white data-[state=open]:rounded-t-none data-[state=open]:border-t data-[state=open]:border-t-gray-400 relative',
                        className
                    )}
                >
                    {isCombobox && (
                        <div className="p-1">
                            <input
                                type="text"
                                className="w-full select-none rounded border p-2 outline-none"
                                value={searchString}
                                onKeyDown={handleSearchKeyDown}
                                onChange={handleSearchChange}
                                placeholder="Filter..."
                            ></input>
                        </div>
                    )}
                    <div className="flex max-h-60 flex-col overflow-y-auto overflow-x-clip">
                        {filteredOptions.map((option, index) => {
                            const active = option === value
                            return (
                                <DropdownMenu.Item
                                    key={option}
                                    data-index={index}
                                    aria-label={option}
                                    ref={active ? activeItemRef : undefined}
                                    data-testid={`${testId}-option-${option}`}
                                    onSelect={() => {
                                        setValue(option)
                                    }}
                                    className={cn(
                                        'relative flex w-full select-none px-[10.5px] py-3 outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-[#e5e5e5] data-[highlighted]:outline-none',
                                        active && 'bg-[#e5e5e5]'
                                    )}
                                >
                                    {option}
                                </DropdownMenu.Item>
                            )
                        })}
                    </div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
}
